<template>
  <v-card tile flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="fields"
            sort-by="order"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Field Manager</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="color3 color3Text--text"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Field
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              label="Field"
                              color="color3"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model.number="editedItem.order"
                              label="Reg Order"
                              type="number"
                              color="color3"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model.number="editedItem.cap"
                              color="color3"
                              label="Capacity"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model.number="editedItem.buffer"
                              label="Buffer"
                              color="color3"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model.number="editedItem.pro"
                              label="Pro Nets"
                              color="color3"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-select
                              v-model="editedItem.divisionIds"
                              :items="tournament.divisions"
                              item-text="name"
                              item-value="id"
                              label="Divisions"
                              color="color3"
                              item-color="color3"
                              chips small-chips deletable-chips multiple
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  ripple
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-icon :color="editedItem.divisionIds.length > 0 ? 'color3' : ''">
                                      {{ icon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                fas fa-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                fas fa-trash
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="color3 color3Text--text"
                @click="dialog = true"
              >
                Add a Field
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="fieldStatus">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="f in fieldStatus.fields"
          :key="f.name"
        >
          <v-card>
            <v-card-title>
              {{f.name}}
            </v-card-title>
            <div class="caption px-3">
              Reserved Courts: {{f.reserved}} | Unreserved: {{f.unreserved}} | Teams: {{totTeams(f.summaries)}}
            </div>
            <v-data-table
              :headers="statusHeaders"
              :items="f.summaries"
              hide-default-footer
              :items-per-page="-1"
              class="elevation-1"
            >
              <template v-slot:[`item.divisionId`]="{item}">
                {{divName(item.divisionId)}}
                <div class="caption">{{item.divisionId}}</div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense justify="space-between">
          <v-spacer></v-spacer>
          <v-btn color="color3" x-small text icon @click.stop="init">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
      </v-row>
    </v-container>
      <template v-if="dirty">
        <v-tooltip top>
          <span>Save</span>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                color="success white--text"
                key="save"
                fab
                bottom
                right
                fixed
                style="margin-right: 120px"
                @click.stop="onSaveClick"
                :loading="loading"
              >
                <v-icon>fas fa-save</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
        </v-tooltip>
        <v-tooltip top>
          <span>Cancel</span>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                color="error white--text"
                fab
                bottom
                right
                fixed
                style="margin-right: 60px"
                @click.stop="init"
                :disabled="loading"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
        </v-tooltip>
      </template>

  </v-card>
</template>

<script>
import sumBy from 'lodash.sumby'
import { mapGetters } from 'vuex'

export default {
  props: ['active'],
  data () {
    return {
      fields: [],
      loading: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      fieldStatus: null,
      editedItem: {
        name: '',
        order: 0,
        cap: 0,
        buffer: 0,
        pro: 0,
        divisionIds: []
      },
      defaultItem: {
        name: '',
        order: 0,
        cap: 0,
        buffer: 0,
        pro: 0,
        divisionIds: []
      },
      headers: [
        {
          text: 'Field',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Reg Order',
          align: 'start',
          value: 'order'
        },
        {
          text: 'Capacity',
          align: 'start',
          value: 'cap'
        },
        {
          text: 'Buffer',
          align: 'start',
          value: 'buffer'
        },
        {
          text: 'Pro Nets',
          align: 'start',
          value: 'pro'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      statusHeaders: [
        {
          text: 'Division',
          align: 'start',
          value: 'divisionId'
        },
        {
          text: 'Teams',
          align: 'start',
          value: 'teams'
        },
        {
          text: 'Courts',
          align: 'start',
          value: 'courts'
        },
        {
          text: 'Availability',
          align: 'start',
          value: 'availability'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    dirty () {
      return (JSON.stringify(this.fields) !== JSON.stringify(this.tournament.jProps.fields))
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Field' : 'Edit Field'
    },
    allDivs () {
      return this.editedItem.divisionIds.length === this.tournament.divisions.length
    },
    someDivs () {
      return this.editedItem.divisionIds.length > 0 && !this.allDivs
    },
    icon () {
      if (this.allDivs) return 'far fa-times-square'
      if (this.someDivs) return 'far fa-minus-square'
      return 'far fa-check-square'
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.allDivs) {
          this.editedItem.divisionIds = []
        } else {
          this.editedItem.divisionIds = [...this.tournament.divisions.map(m => m.id)]
        }
      })
    },
    init () {
      this.fields = JSON.parse(JSON.stringify((this.tournament.jProps && this.tournament.jProps.fields) || []))
      this.fields.forEach(f => {
        f.order = +f.order
        f.cap = +f.cap
        f.buffer = +f.buffer
        f.pro = +f.pro
        if (!f.divisionIds) f.divisionIds = []
      })
      this.editedItem.divisionIds = this.tournament.divisions.map(m => m.id)
      this.defaultItem.divisionIds = this.tournament.divisions.map(m => m.id)
      this.getAvail()
    },
    editItem (item) {
      this.editedIndex = this.fields.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.fields.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.fields.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedIndex = -1
      })
    },
    getAvail () {
      this.$VBL.tournament.getFieldAvail(this.tournament.id)
        .then(r => {
          this.fieldStatus = r.data
        })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.fields[this.editedIndex], this.editedItem)
      } else {
        this.fields.push(this.editedItem)
      }
      this.close()
    },

    onSaveClick () {
      this.loading = true
      if (!this.tournament.jProps) this.tournament.jProps = {}
      this.tournament.jProps.fields = this.fields
      this.tournament.saveJProps()
        .then(r => { this.init() })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    divName (id) {
      const d = this.tournament.divisions.find(f => f.id === id)
      return d ? d.name : ''
    },
    totTeams (summaries) {
      return sumBy(summaries, 'teams')
    }
  },
  watch: {
    active: 'init',
    fields2: {
      deep: true,
      handler () {
        this.fields.forEach(f => {
          f.order = +f.order
          f.cap = +f.cap
          f.buffer = +f.buffer
          f.pro = +f.pro
        })
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
